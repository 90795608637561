@use "../../styles/variables" as *;

.emptyContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 24px;

  .displayText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: $dark-blue;

    p {
      margin: 0;
    }
  }
}
