.container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .text {
    margin-right: 8px;
    word-break: break-all;
  }
}
