.icon {
  font-size: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.emptyPlan {
  margin-top: 105px;
}

.collapsePanelHeader {
  width: 90%;
  display: inline-flex;

  .headerText {
    margin-right: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}

.collapseCustomCollapse {
  margin-bottom: 16px;
  border-radius: 4px;
}

:global {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    height: 56px;
    padding: 16px;
  }
}
