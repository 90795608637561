.editableCellInputNumber {
  :global {
    min-width: 68px;
    width: 100%;
    margin: 11px 0 11px 0;
    .ant-input-number-handler-wrap {
      display: none;
    }

    .ant-input-number:hover .ant-input-number-handler-wrap {
      opacity: 0;
    }
  }
}
