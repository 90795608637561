.productInput {
  width: 245px;
  margin-bottom: 24px;
}

.table {
  tbody {
    > tr:hover {
      cursor: unset;
    }
  }
}
