@use "../../styles/variables" as *;

.modal {
  min-width: 520px;

  :global {
    .ant-modal-close-x {
      color: $dark-blue;
    }

    .ant-modal-body {
      padding: 0;
    }

    .ant-form-item-has-error {
      height: 32px;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-modal-header {
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }

    .ant-space-vertical {
      font-size: 16px;
      padding: 14px 24px 24px 24px;
      min-height: 5rem;
    }
  }
}
