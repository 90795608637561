@use "../../../styles/variables" as *;
@use "../../../styles/mixins";

.entitlementSection {
  .subtitle {
    @include mixins.Heading3;
  }

  .table {
    .arrow {
      cursor: unset;
    }

    .disable {
      cursor: not-allowed;
    }

    .hand {
      cursor: pointer;
    }

    :global {
      .ant-table-cell {
        .ant-empty-normal {
          margin: 20px 0;
        }
      }
    }
  }
}
