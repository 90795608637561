@use "../../styles/variables" as *;

.configureForm {
  .header {
    display: flex;
    align-items: center;
    gap: 102px;
    margin-bottom: 10px;
    color: $greyish-brown;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-explain-success {
      display: none !important;
    }
  }

  .addButton {
    padding: 0;
    color: $dark-blue;
    border: none;
    box-shadow: none;

    .antIcon {
      padding-top: 4px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: inherit;
    }

    background: transparent;
  }

  .fieldContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}
