@use "../../styles/variables" as *;

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 37px;
  height: 37px;
  background-image: url("../../assets/icons/icon-loading.svg");
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
