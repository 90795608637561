@use "../../../../styles/variables" as *;

.searchForm {
  width: 1080px;
  justify-content: space-between;
  padding-top: 32px;

  .searchRow {
    display: flex;
    gap: 24px;

    .searchItem {
      margin-right: 0;
    }

    .fistColumnLabel {
      :global {
        .ant-form-item-label {
          width: 112px;
        }
      }
    }

    .secondColumnLabel {
      :global {
        .ant-form-item-label {
          width: 120px;
        }
      }
    }
  }

  .buttonGroup {
    width: 100%;
    justify-content: flex-end;
  }
}

.popover {
  left: calc(#{$sider-width} + 2.5rem) !important;

  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}

.tags {
  :global(.ant-tag) {
    margin-top: 0;
    margin-bottom: map-get($spacers, 4);
  }
}
