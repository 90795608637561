@use "../../styles/variables" as *;

.modal {
  min-width: 520px;

  :global {
    .ant-modal-close-x {
      color: $dark-blue;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-modal-header {
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }

    .ant-modal-body {
      font-size: 16px;
    }
  }

  .modal__content {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    background-color: $brown-grey-six;
    padding: 24px;
  }

  .modal__list__item {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    span:first-child {
      font-size: 14px;
      font-weight: 400;
    }
    span:last-child {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.form {
  .formItem {
    :global {
      .ant-form-item-control {
        min-height: 60px;
      }
      .ant-form-item-explain-success {
        display: none !important;
      }
    }
  }

  :global {
    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-form-item-control {
      min-height: 24px;
    }
  }
}
