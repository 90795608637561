@use "variables" as *;
@use "mixins";

.ant-table-thead > tr > th {
  font-weight: bold;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding-top: 13px;
  padding-bottom: 13px;

  &:first-child {
    padding-left: 32px !important;
  }

  &:last-child {
    padding-right: 32px !important;
  }
}

.ant-table-cell {
  .ant-empty-normal {
    margin: 205px 0;
  }
}

.ant-select-item-empty {
  .ant-empty-normal {
    margin: 55px 0;
  }
}

.ant-btn-link[disabled] {
  color: $brown-grey-three;
}

.ant-btn-round {
  border-radius: 16px;
}

.ant-btn-default {
  font-weight: 500;
  color: $dark-blue;
  border-color: $dark-blue;
}

.ant-btn-primary {
  font-weight: 500;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
  margin-right: 0;
}

.ant-pagination-item-active {
  border-color: transparent;

  a {
    color: $vivid-blue !important;
  }
}

.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 4px;
}

.ant-pagination-item {
  border: none;
  margin-right: 4px;

  a {
    padding: 0 4px;
  }

  &:hover {
    background-color: $brown-grey-five;
  }
}

.ant-table-pagination-right {
  justify-content: unset;

  .ant-pagination-total-text {
    margin-left: 32px;
    color: $brown-grey;
  }

  .ant-pagination-prev {
    margin-left: auto;
  }
}

.ant-message-notice-content {
  .ant-message-custom-content {
    color: $greyish-brown;

    .anticon {
      top: 2px;
    }
  }

  border-radius: 4px;
  padding: 9px 16px;
}

.ant-tabs-tab {
  font-size: 16px !important;
  line-height: 19px !important;
  font-weight: bold !important;
  color: $brown-grey;

  &-active &-btn {
    @extend .ant-tabs-tab;
    color: $dark-blue;
  }
}

.ant-tag {
  border: none;
  border-radius: 16px;
  padding: 0 20px;
  margin-top: 20px;
  font-size: 14px;
}

.ant-tag .ant-tag-close-icon {
  font-size: 12px;
  opacity: 0.4;
  transition: opacity 0.3s ease;
}

.ant-tag .ant-tag-close-icon:hover {
  opacity: 1;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background-color: $brown-grey-five;
}

.ant-dropdown-menu {
  box-shadow: 3px 3px 12px 0 rgba(0, 35, 90, 0.16), 0 0 4px 0 rgba(0, 35, 90, 0.15);
}

.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}

.ant-input-affix-wrapper {
  .anticon {
    color: $brown-grey-three;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: $greyish-brown;
}

.ant-form-vertical .ant-form-item-label > label::after {
  display: inline;
}

.ant-breadcrumb-separator {
  margin: 0 2px;
}

.ant-select-item {
  color: $brown-grey-three;

  &-option-active:not(.ant-select-item-option-disabled) {
    background-color: $pale-grey;
    font-weight: 500;
    color: $dark-blue;
  }

  &-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $pale-grey;
    font-weight: 500;
    color: $dark-blue;
  }
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 4px;
  border: 1px solid $select-item-border;
  padding-top: 0;
  background-color: $pale-grey;
  font-weight: 500;
  color: $dark-blue;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none;
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px;
}

/* ant select styles */
.ant-select {
  & > &-arrow {
    color: $dark-blue;
  }

  &-disabled > &-arrow {
    color: rgb(0, 0, 0, 0.25);
  }
}

/* ant input styles */
.ant-input-number-input {
  text-align: center;
}

.ant-divider {
  border-top: 1px solid $brown-grey-seven;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid $brown-grey-seven;
}

.cursor-default {
  cursor: default;
}

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: $brown-grey-five;
}

.ant-form-item-explain-success {
  visibility: hidden;
}
