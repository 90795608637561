@use "../../../../styles/variables" as *;

.viewCodesButton {
  padding: 4px 0;
}

.subtitleContainer {
  padding-left: 10px;
  padding-bottom: 24px;
}

.table {
  .arrow {
    cursor: unset;
  }

  .disable {
    cursor: not-allowed;
  }

  .hand {
    cursor: pointer;
  }

  :global {
    .ant-table-cell {
      .ant-empty-normal {
        margin: 20px 0;
      }
    }
  }
}
