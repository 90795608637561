@use "../../styles/variables" as *;

.rteDisabled {
  :global {
    .ql-container {
      border-radius: 4px;
      min-height: 200px;
      font-size: 14px;
      color: $greyish-brown;
      font-weight: 400;
      line-height: 18px;
      font-family: "CeraPRO", sans-serif;
    }
  }
}

.rte {
  :global {
    .ql-toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      min-height: 200px;
    }
    .ql-editor {
      min-height: 200px;
    }
  }
}
