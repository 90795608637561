@use "../../../../styles/variables" as *;
@use "../../../../styles/mixins";

.disclosureForm {
  .divider {
    margin-top: 0;
  }
}

.tabCardContainer {
  @include mixins.tab-container;
}

