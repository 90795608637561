@use "../../../../../styles/variables" as *;

.form {
  .fixedSpace {
    :global {
      .ant-space-item {
        height: 68px;
      }
    }
  }

  .smallFormItem {
    width: 229.5px;

    :global {
      .ant-input-number {
        width: 100%;
      }

      .ant-input-number-input {
        text-align: left;
      }

      .ant-radio-group .ant-radio-wrapper {
        margin: 0;

        span.ant-radio + * {
          padding-left: 8px;
          padding-right: 0;
        }
      }
    }
  }

  .smallFormItem:global(.ant-form-item-has-error .ant-form-item-explain) {
    line-height: 1;
  }

  .formItem {
    width: 491px;
  }

  .largeFormItem {
    width: 1014px;

    :global {
      .ant-select-selection-item {
        border-radius: 4px;
        border: 1px solid $disabled-select-item-border;
        background-color: $background;
      }
    }
  }

  :global {
    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-form-item-explain-success {
      display: none !important;
    }
  }

  .basePriceRow,
  .descriptionRow {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 32px;
  }

  .formHelpText {
    color: $brown-grey-two;
  }

  .planImage {
    display: flex;
    flex-direction: column;
    gap: 8px;

    :global {
      .ant-form-item-control {
        min-height: 32px;
      }
    }

    :global {
      .ant-image {
        width: fit-content;

        .ant-image-img {
          height: 104px;
          width: auto;
        }
      }
    }
  }
}
