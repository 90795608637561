@use "../../../styles/variables" as *;
@use "../../../styles/mixins";

.giftCardBalanceSection {
  .subtitle {
    @include mixins.Heading3;
  }

  .table {
    .arrow {
      cursor: unset;
    }
  }
}
