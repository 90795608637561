@use "../../../../../styles/variables" as *;
@use "../../../../../styles/mixins";

.promotionForm {
  .divider {
    margin-top: 0;
  }

  .subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mixins.Heading3;
    margin-bottom: 24px;
  }
}
