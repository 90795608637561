@use "../../../../styles/variables" as *;

.secondTitle {
  font-size: 20px;
  line-height: 28px;
  color: $dark-blue;
  font-weight: 700;
}

.planHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productField {
  font-size: 16px;
  font-weight: 500;
}

.divider {
  margin-top: 0;
}

.emptyPlan {
  margin-top: 105px;
}

.deleteButton {
  :global {
    .ant-btn[disabled] {
      background: $white;
    }
  }
}
