@use "../../../../styles/variables" as *;

.modal {
  :global {
    .ant-modal-close-x {
      color: $dark-blue;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-modal-header {
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }

    .ant-modal-body {
      font-size: 16px;
    }
  }
}

.deeplink {
  transform: translateY(-24px);

  :global {
    .ant-btn-link {
      padding: 0;
      height: auto;
      border: none;
    }
  }
}
