@use "../../../../styles/mixins";

.descriptionDetailContainer {
  :global {
    .ant-divider-horizontal {
      margin-top: 0;
    }

    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: bold;
      min-width: 0;
    }
  }
}


.tabCardContainer {
  @include mixins.tab-container;
}
