@use "../../../../../styles/variables" as *;

.modal {
  min-width: 1062px;
  min-height: 696px;

  :global {
    .ant-modal-close-x {
      color: $dark-blue;
    }

    .ant-modal-body {
      padding: 24px;
      min-height: 621px;
    }

    .ant-form-item-has-error {
      height: 32px;
    }

    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-modal-header {
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }
  }
}
