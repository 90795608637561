@use "../../../../styles/variables" as *;

.container {
  .addNewUpsellBtn {
    width: 144px !important;
    margin-left: 0;
    padding-left: 0;
    color: $dark-blue;
  }

  .minusBtn {
    width: 0 !important;
    margin-left: 0;
    padding-left: 0;
    color: $dark-blue;
  }

  :global {
    .ant-form-item-control-input-content {
      font-weight: unset !important;
    }
  }
}

.dropdown {
  :global {
    .ant-select-dropdown {
      width: 640px !important;
    }
  }
}

.checkbox {
  margin-top: 36px;
}
