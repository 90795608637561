@use "../../../../styles/variables" as *;

.form {
  margin: map-get($spacers, 2) 0 $spacer 0;
  padding: $spacer map-get($spacers, 4);
  min-height: calc(100vh - 168px);
  background-color: $white;
  border-radius: 4px;
  border: 1px solid #e6eaf2;

  :global {
    .ant-btn {
      width: 92px;
    }
    .ant-tabs-content {
      margin: 16px 0;
    }
    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-divider-horizontal {
      margin-top: 16px;
    }
    .ant-form-item-control {
      height: 47px;
    }
  }

  .smallFormItem {
    width: 232px;
    &:global(.ant-form-item-has-error .ant-form-item-explain) {
      line-height: 1;
    }
    .littleInput {
      :global {
        .ant-input {
          text-align: center;
          width: 87px;
        }
        .ant-col {
          margin-left: 0;
        }
        .ant-form-item-control {
          height: 32px;
        }
      }
    }
    :global {
      .ant-select {
        width: 232px;
      }
      .ant-form-item-control {
        height: 47px;
      }
    }
  }
  .formItem {
    width: 496px;
  }
  .bigFormItem {
    width: 1096px;
    :global {
      .ant-form-item-control {
        min-height: 32px;
        margin-bottom: 16px;
      }
    }
  }
  .textArea {
    width: 1024px;
    :global {
      .ant-form-item-control {
        height: 98px;
      }
      .ant-input {
        height: 80px;
      }
    }
  }
}

.confirmModal {
  width: 520px !important;
}

.tab {
  > section {
    margin-left: 20px;
    width: 1063px;
  }
}
