@use "../../../styles/variables" as *;

.header {
  margin-bottom: map-get($spacers, 4);
}

.divider {
  margin: 0;
}

.isbnInput {
  width: 204px;
}

.filter {
  margin: 0 0 map-get($spacers, 4) 0;
}

.tags {
  :global(.ant-tag) {
    margin-top: 0;
    margin-bottom: map-get($spacers, 4);
  }
}

.row:hover {
  cursor: unset;
}

.scheduledPopover {
  :global {
    .ant-popover-inner {
      background-color: white !important;
    }

    .ant-popover-arrow-content {
      background-color: white !important;
    }

    .ant-popover-inner-content {
      padding: 6px 8px;
    }
  }

  .deleteBtn {
    padding: 0;
    margin-left: 4px;
    height: 24px;
  }
}

.alarmIcon {
  margin-left: 6px;
  cursor: pointer;
}
