@use "../../../../../styles/variables" as *;

.deeplinkForm {
  textarea {
    min-height: 5rem;
  }

  :global {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content {
      margin: 32px 0;
    }

    .ant-row.ant-form-item {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 0;
    }

    .ant-form-item-label {
      align-self: start;
    }

    .ant-form-item-control {
      min-height: 56px;
    }

    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: bold;
      min-width: 0;

      .ant-input-textarea-show-count::after {
        font-weight: normal;
        font-size: 10px;
      }
    }
  }

  .titleItem {
    textarea {
      min-height: auto !important;
      height: 32px !important;
    }
  }

  .commentItem {
    min-height: 136px;
  }
}
