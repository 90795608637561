@use "../../styles/variables" as *;
.configureTable {
  margin-top: 10px;

  .table {
    :global {
      .ant-form-item-control-input {
        .ant-form-item-has-success {
          padding: 0;
        }
      }

      .ant-table-filter-trigger:hover {
        background-color: transparent;
      }

      .ant-form-item {
        padding-top: 11px;
        height: 54px;
      }
    }
  }

  .addButton {
    width: 100%;
    padding: 0;
    color: $vivid-blue;
    box-shadow: none;
    margin-top: 16px;
    border-color: $vivid-blue;
    border-radius: 4px;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
    }

    background: transparent;
  }
}
