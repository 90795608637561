.filter {
  column-gap: 12px;

  & > :last-child {
    position: relative;
    width: auto;
    margin-bottom: 24px;
  }
}

:global {
  .DropdownInput_overlay__2HbSH {
    overflow-y: auto !important;
  }
}

.courseInput {
  width: 245px;
  margin-bottom: 24px;
}

.table {
  tbody {
    > tr:hover {
      cursor: unset;
    }
  }
}

.accountHolder {
  font-size: 12px;
}
