@use "../../styles/variables" as *;
@use "../../styles/mixins";

.navbar {
  border-radius: 0 0 4px 0;
  box-shadow: 4px 0 8px 0 rgba(0, 35, 90, 0.06), 0 0 2px 0 rgba(0, 35, 90, 0.04);
  height: auto;

  :global {
    .ant-menu {
      border-right: none;

      &-item {
        @include mixins.Body-navigation-items;
        display: flex;
        align-items: center;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        padding-top: map-get($spacers, 3) !important;
        padding-bottom: map-get($spacers, 3) !important;
        line-height: 1em !important;
        height: auto !important;

        &:hover {
          color: $vivid-blue;

          g {
            fill: $vivid-blue;
          }

          rect {
            stroke: $vivid-blue;
          }
        }

        &-icon {
          font-size: 20px;
          line-height: 24px !important;
        }

        &-selected {
          @include mixins.Body-navigation-selected;
          background-color: $pale-grey !important;

          .anticon {
            font-size: 20px;
          }

          path {
            stroke-width: 0.5px;
            stroke: $dark-blue;
          }

          rect {
            stroke-width: 1.5px;
            stroke: $dark-blue;
          }

          &:hover {
            g {
              fill: $vivid-blue;
            }

            path {
              stroke: $vivid-blue;
            }

            rect {
              stroke: $vivid-blue;
            }
          }
        }
      }

      &-submenu {
        @include mixins.Body-navigation-items;

        &-title {
          padding-top: map-get($spacers, 3) !important;
          padding-bottom: map-get($spacers, 3) !important;
          margin-top: 0;
          margin-bottom: 0;
          line-height: 1em !important;
          height: auto !important;

          &:hover {
            color: $vivid-blue;
          }
        }
      }
    }

    .ant-menu-item[data-menu-id$="products/description"] .ant-menu-title-content,
    .ant-menu-item[data-menu-id$="marketing/upsell-downgrade"] .ant-menu-title-content {
      line-height: 1.5;
    }

    .ant-layout-sider-trigger {
      background: $pale-grey;
      position: absolute;

      .anticon {
        font-size: 20px;
      }
    }
  }
}
