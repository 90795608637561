@use "../../../../../styles/variables" as *;

.productInfoForm {
  display: flex;
  column-gap: 32px;

  .productInfoFormItem {
    width: 48%;
  }
}

.selectDropdown {
  :global {
    .ant-select-item-option-disabled {
      background-color: $background;
    }
  }
}
