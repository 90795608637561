@use "../../../../styles/variables" as *;
.filter {
  margin: 0 0 map-get($spacers, 4) 0;
}

.statusIndicator {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}

.ProductTable {
  :global {
    .ant-form-item-control-input {
      .ant-form-item-has-success {
        padding: 0;
      }
    }

    .ant-form-item-with-help {
      padding-top: 11px;
      max-height: 54px;
    }
  }
}

.LinkButton {
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: #1644f9;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
}

.tags {
  :global(.ant-tag) {
    margin-top: 0;
    margin-bottom: map-get($spacers, 4);
  }
}

.buttons {
  :global {
    .ant-btn-primary[disabled] {
      background: $brown-grey-four;
      color: $white;
    }
  }
}

.publishPlans {
  tbody {
    > tr:hover {
      cursor: default;
    }
  }
}
