@use "../../../styles/variables" as *;

.pauseModal {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  > span {
    font-size: 14px;
    font-weight: 400;
  }
  > p {
    font-size: 16px;
    line-height: 24px;
  }
}

.radioBold {
  font-weight: 500;
}

.customSelect {
  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: auto;
    }

    .ant-select-selector {
      .ant-select-selection-item {
        white-space: normal;
        word-break: break-word;
        line-height: 18px;
        padding-top: 7px;
        padding-bottom: 7px;
      }
    }

    .ant-select-item-option-content {
      white-space: normal;
      word-break: break-word;
    }
  }
}

.inputNumber {
  :global {
    .ant-input-number-input {
      text-align: left;
    }

    .ant-col {
      margin-left: 0;
    }

    .ant-form-item-control {
      height: 32px;
    }
  }
}
