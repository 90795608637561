.invoiceInput {
  width: 245px;
}

.organizationSelect {
  width: 245px;
}

.table {
  min-width: 1062px;

  :global {
    .ant-btn {
      padding: 0;
      height: auto;
      border: none;
    }
  }
}

.form {
  & > div {
    display: flex;
    flex-direction: column;
  }
}

:global {
  .ant-table-filter-dropdown .ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected {
    color: #000;
    background-color: #fff;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
