@mixin oval($color) {
  display: flex;
  align-items: center;

  &::before {
    display: inline-block;
    content: " ";
    width: 8px;
    height: 8px;
    border-radius: 4px;
    margin-right: 4px;
    background-color: #{$color};
  }
}

.active {
  @include oval(#00a854);
}

.staging {
  @include oval(#f8a832);
}

.readyForReview {
  @include oval(#2f9ce1);
}

.archived {
  @include oval(#9c9c9c);
}
