@use "../../../styles/variables" as *;

.header {
  margin-bottom: map-get($spacers, 4);
}

.divider {
  margin: 0;
}

.emailInput {
  width: 250px;
}

.filter {
  margin: 0 0 map-get($spacers, 4) 0;
}

.tags {
  :global(.ant-tag) {
    margin-top: 0;
    margin-bottom: map-get($spacers, 4);
  }
}

.table {
  tbody {
    > tr:hover {
      cursor: unset;
    }
  }
}
