@use "./variables" as *;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote("../assets/" + $path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin Heading1 {
  font-size: 26px;
  font-weight: bold;
  color: $greyish-brown;
}

@mixin Body-navigation-selected {
  font-size: 16px;
  font-weight: bold;
  color: $dark-blue;
}
@mixin Body-navigation-items {
  font-size: 16px;
  color: $greyish-brown;
}
@mixin Body-table-header {
  font-size: 14px;
  font-weight: bold;
  color: $brown-grey;
}
@mixin Body-button-primary {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
}
@mixin Body-text-button-disable {
  font-size: 14px;
  color: $brown-grey-three;
}
@mixin Body-text-button {
  font-size: 14px;
  color: $vivid-blue;
}
@mixin Body-table-items {
  font-size: 14px;
  color: $black;
}
@mixin Body-inputbox {
  font-size: 14px;
  color: $greyish-brown;
}

@mixin Body-footer {
  height: $footer-height;
  font-size: 12px;
  color: $greyish-brown;
  background-color: $white;
}

@mixin Heading3 {
  font-size: 18px;
  font-weight: 700;
  color: $greyish-brown;
}


@mixin tab-container {
  display: flex;
  flex-direction: column;

  :global {
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;

      .ant-tabs-tab {
        border-radius: 4px 4px 0 0;
        border: 1px solid #DADEE7;
      }

      .ant-tabs-tab-active {
        border-bottom-color: #fff;
      }
    }


    .ant-tabs-content-holder {
      padding: 24px;
      border: 1px solid #DADEE7;
      border-top: none;
    }
  }
}
