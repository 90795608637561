@use "../../../../styles/variables" as *;

.searchForm {
  width: 1054px;
  justify-content: space-between;
  padding-top: 32px;

  :global {
    /* set input content width */
    .ant-form-item-control-input-content > * {
      width: 408px;
    }

    .ant-picker {
      width: 176px;
    }
  }

  .buttonGroup {
    width: 100%;
    justify-content: flex-end;
  }
}

.popover {
  left: calc(#{$sider-width} + 2.5rem) !important;

  :global {
    .ant-popover-arrow {
      display: none;
    }
  }
}
