.icon {
  font-size: 12px;
}

.collapsePanelHeader {
  width: 90%;
  display: inline-flex;

  .headerText {
    margin-right: 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
}

.collapseCustomCollapse {
  margin-bottom: 16px;
  border-radius: 4px;

  .boldFormItem {
    :global {
      .ant-form-item-control-input-content {
        font-size: 16px;
        font-weight: bold;
        min-width: 0;
      }
    }
  }
}

:global {
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    height: 56px;
    padding: 16px;
  }
}
