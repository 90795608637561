.indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4ccb4e;
  margin-right: 5px;

  &.hasExpired {
    background-color: red;
  }

  &.aboutToExpire {
    background-color: orange;
  }
}
