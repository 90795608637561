@use "../../../styles/variables" as *;
@use "../../../styles/mixins";

.paymentMethodSection {
  .subtitle {
    @include mixins.Heading3;
  }

  .paymentMethodItem {
    > p:first-child {
      font-size: 14px;
      font-weight: 400;
    }

    > p:last-child, span p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }

  :global {
    .ant-divider-horizontal {
      margin: 0;
    }
  }
}
