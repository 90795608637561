@use "../../../styles/variables" as *;

.table {
  min-width: 1062px;

  :global {
    .ant-btn {
      padding: 0;
      height: auto;
      border: none;
    }
  }
}

.modal {
  width: 520px !important;
}

.filters {
  column-gap: 12px;

  // input tag
  & > :first-child {
    width: 372px;
    max-width: 372px;
    flex-basis: 372px;
  }

  & > :last-child {
    position: absolute;
    right: 32px;
  }
}
