.verifyForm {
  margin-top: 20px;
}

.button {
  padding-left: 0;
}

.modal {
  width: auto !important;
  display: flex;
  max-width: 1056px;
  justify-content: center;

  .content {
    max-height: 520px;
    overflow-y: auto;
  }

  .clipboard {
    margin: 32px 0 -56px 0;

    > svg {
      margin: -5px 0px;
    }

    :global {
      .ant-btn-link {
        padding-left: 4px;
      }
    }
  }

  :global {
    .ant-modal-confirm-body > .anticon {
      font-size: 24px;
    }

    .ant-modal-confirm-content {
      font-size: 16px;
      padding: 4px 0 0 0;
      margin-left: 72px;
      margin-right: 32px;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-btn-primary {
      margin-right: 30px;
      width: 92px;
    }
  }
}
