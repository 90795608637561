@use "../../styles/variables" as *;

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: $min-width;
  width: 100%;
  height: $header-height;
  padding-left: 54px; // to align Logo with Menu title
  padding-right: map-get($spacers, 4);
  background-color: $dark-blue;
  color: white;
  position: absolute;
  top: 0;
  z-index: 1;
}

.headerLogoImage {
  height: 1rem;
  background: url("../../assets/images/logo.png") center no-repeat;
  background-size: contain;
}

.profile {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  & > * {
    margin-right: map-get($spacers, 2);
  }

  &::after {
    margin-top: map-get($spacers, 2);
    content: "";
    border: 6px solid transparent;
    border-top-color: $white;
  }
}

.logout {
  font-size: 1rem;
  width: 184px;
  display: flex;
  align-items: center;
}

.docs {
  color: white;
  font-size: 16px;
  font-weight: 500;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: white;
  }

  svg {
    margin-right: 5px;
    margin-bottom: -5px;
  }
}
