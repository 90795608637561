@use "../../../../styles/variables" as *;

.Form {
  .divider {
    margin-top: 0;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 700;
    color: $greyish-brown;
    margin-bottom: 24px;
  }
}
