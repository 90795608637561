.formContainer {
  width: 1056px;
}

.scheduleContainer {
  display: flex;
  justify-content: flex-end;

  .scheduleContent {
    display: flex;
    align-items: center;
    width: 270px;

    .icon {
      margin-right: 5px;
    }

    .button {
      padding: 0;
      width: 60px;
    }
  }
}
