.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  column-gap: 24px;
  justify-items: stretch;
  width: 900px;
  border-bottom: solid 1px #dadee7;

  // Form.Items
  & > div {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  // Form.Item label
  & > div > :first-child {
    flex-grow: 1;
  }

  // Form.Item value
  & > div > :last-child {
    min-width: 70%;
    max-width: 70%;
  }
}

.buttonGroup {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
  column-gap: 12px;
}
