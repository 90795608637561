@use "../../styles/variables" as *;

.hint {
  p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
  }

  .title {
    font-weight: 500;
    margin-bottom: 8px;
  }

  .detail {
    font-weight: 400;
    color: $brown-grey-two;
  }
}
