@use "../../../styles/mixins";

.giftSection {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .subtitle {
    @include mixins.Heading3;
  }

  :global {
    .ant-table-cell {
      .ant-empty-normal {
        margin: 20px 0;
      }
    }
  }
}
