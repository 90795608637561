@use "../../../../styles/variables" as *;
@use "../../../../styles/mixins";

.saveOfferForm {
  .tabCardContainer {
    @include mixins.tab-container;
  }

  .divider {
    margin-top: 0;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 700;
    color: $greyish-brown;
    margin-bottom: 24px;
  }

  .extendDays {
    :global {
      .ant-input-number-input {
        text-align: left;
      }

      .ant-col {
        margin-left: 0;
      }

      .ant-form-item-control {
        height: 32px;
      }
    }
  }

  .littleInput {
    :global {
      .ant-input-number-input {
        text-align: left;
      }

      .ant-col {
        margin-left: 0;
      }
    }
  }

  .largeFormItem {
    :global {
      .ant-select-selection-item {
        border-radius: 4px;
        border: 1px solid $disabled-select-item-border;
        background-color: $background;
      }

      .ant-form-item-control {
        min-height: 0;
      }
    }
  }

  .searchIcon {
    color: $brown-grey-three;
  }

  .infoIcon {
    color: $brown-grey;
  }
}
