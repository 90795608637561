@use "../../../../styles/variables" as *;

.detailContainer {
  margin: map-get($spacers, 2) 0 $spacer 0;
  padding: $spacer map-get($spacers, 4);
  min-height: calc(100vh - 168px);
  background-color: $white;
  border-radius: 4px;
  border: 1px solid #e6eaf2;

  textarea {
    min-height: 5rem;
  }

  :global {
    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-content {
      margin: 32px 0;
    }

    .ant-tabs-tab {
      margin: 0 32px 0 0;
    }

    .ant-row.ant-form-item {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 0;
    }

    .ant-form-item-label {
      align-self: start;
    }

    .ant-form-item-control {
      min-height: 56px;
    }

    .ant-form-item-control-input-content {
      font-size: 16px;
      font-weight: bold;
      min-width: 0;
    }

    .ant-divider-horizontal {
      margin-top: 0;
    }

    .ant-btn {
      width: 92px;
    }
  }
}

.basicForm {
  :global {
    .ant-col .ant-form-item-control-input {
      margin-right: 16px;
    }

    .ant-form-item-control-input-content {
      font-weight: unset;
    }

    .ant-btn-link {
      color: $dark-blue;
      padding-left: 0;
    }
  }

  .upsellDescriptionItem {
    min-height: 136px;
  }
}

.modal {
  width: auto !important;
  display: flex;
  max-width: 1056px;
  justify-content: center;

  .content {
    max-height: 520px;
    overflow-y: auto;
  }

  .clipboard {
    margin: 32px 0 -56px 0;

    > svg {
      margin: -5px 0px;
    }

    :global {
      .ant-btn-link {
        padding-left: 4px;
      }
    }
  }

  :global {
    .ant-modal-confirm-body > .anticon {
      font-size: 24px;
    }

    .ant-modal-confirm-content {
      font-size: 16px;
      padding: 4px 0 0 0;
      margin-left: 72px;
      margin-right: 32px;
    }

    .ant-modal-confirm-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ant-btn-primary {
      margin-right: 30px;
      width: 92px;
    }
  }
}

.verifyForm {
  margin-top: 20px;
}

.confirmModal {
  :global {
    .ant-modal-content {
      padding: 0;
      width: 550px;
    }
    .ant-modal-close-x {
      color: $dark-blue;
    }
    .ant-modal-header {
      border-bottom: 1.5px solid $dark-blue;
      padding: 14px 24px;
    }
    .ant-modal-title {
      font-weight: bold;
      font-size: 18px;
    }
    .antd-modal-body {
      font-size: 16px;
      padding: 14px 24px 24px 24px;
      min-height: 5rem;
    }
    .ant-modal-confirm-content {
      font-size: 16px;
      padding: 14px 24px 24px 24px;
      min-height: 5rem;
    }
    .ant-modal-footer {
      padding: 10px 16px;
      margin-top: 0;
    }
  }
}

.bold {
  font-weight: 450;
  font-size: 15px;
}

.radios {
  margin: 12px;
}
