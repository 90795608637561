@use "../../../styles/variables" as *;
@use "../../../styles/mixins";

.customerBasicInfoSection {
  .subtitle {
    @include mixins.Heading3;
  }

  :global {
    .ant-row.ant-form-item {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 24px;
    }

    .ant-divider-horizontal {
      margin: 0;
    }

    .ant-form-item-control-input-content {
      > span, li {
        font-weight: 500;
        font-size: 16px;

        svg {
          width: 2em;
        }
      }

      > span div {
        display: flex;
        align-items: center;
      }

      .ant-picker {
        width: 115px;
      }
    }

    .ant-form-item-control .ant-radio-group {
      display: flex;

      > label {
        flex-grow: 1;
      }
    }
  }
}

.paragraph {
  display: flex;
  white-space: normal;
  column-gap: 4px;
  overflow-wrap: anywhere;
}

.marketingOptIn {
  .subtitle {
    margin-bottom: 24px;
  }
  margin-bottom: 8px;
}
