@use "../styles/variables" as *;

.mainWrapper {
  display: flex;
  flex-grow: 1;
  margin-top: $header-height;
  min-height: 0;
}

.confirmModal {
  width: 520px !important;
}
