@use "../../styles/variables" as *;

.namiCheck {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 10px;
  margin-bottom: 26px;

  .namiCheckTitle {
    color: rgba(0, 0, 0, 0.85);
    line-height: 20px;
    margin-right: 10px;
  }

  .namiCheckReminder {
    color: red;
    font-size: 10px;
    line-height: 20px;
  }

  :global {
    .ant-checkbox-wrapper {
      flex-direction: row-reverse;

      span {
        padding-left: 0;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
