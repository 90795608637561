@use "../../../styles/variables" as *;

.basePriceNameInput {
  width: 220px;
}

.filter {
  margin: 0 0 map-get($spacers, 4) 0;
}

.basePriceTable {
  :global {
    .ant-form-item-control-input {
      .ant-form-item-has-success {
        padding: 0;
      }
    }

    .ant-form-item-with-help {
      padding-top: 11px;
      max-height: 54px;
    }
    .ant-table-filter-trigger:hover {
      background-color: transparent;
    }
  }
}

.fontBold {
  font-weight: bold;
}

.basePriceNameButton {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: #1644f9;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
}

.tooltip {
  &:hover {
    background-color: transparent;
  }
}
