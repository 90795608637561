// Color system
$white: #fff;
$black: #000;
$greyish-brown: #474545;
$pale-grey: #f3f4ff;
$dark-blue: #1c2154;
$vivid-blue: #1644f9;
$brown-grey: #858585;
$brown-grey-two: #7f7f7f;
$brown-grey-three: #9c9c9c;
$brown-grey-four: #cccccc;
$brown-grey-five: #e3e3e8;
$brown-grey-six: #f8f8f8;
$brown-grey-seven: #dadee7;
$background: #f4f4f4;
$pale-grey-two: #f2f3fa;
$pale-blue: #f3f5fe;
$border-grey: #e5e5e5;
$disabled-color: #bfbfbf;
$select-item-border: #bfc5e4;
$disabled-select-item-border: #cccccc;

// Typography
$font-size-root: null !default;
$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$min-width: 1366px;
$min-height: 768px;

$header-height: 64px;
$sider-width: 200px;
$footer-height: 64px;
